import { Link } from "gatsby"
import React from "react"
import GcIcon from "../images/icon.inline.svg"

function hasClass(elem, className) {
  return new RegExp(" " + className + " ").test(" " + elem.className + " ")
}

function toggleClass(elem, className) {
  var newClass = " " + elem.className.replace(/[\t\r\n]/g, " ") + " "
  if (hasClass(elem, className)) {
    while (newClass.indexOf(" " + className + " ") >= 0) {
      newClass = newClass.replace(" " + className + " ", " ")
    }
    elem.className = newClass.replace(/^\s+|\s+$/g, "")
  } else {
    elem.className += " " + className
  }
}

const GcHeader = ({ siteTitle, className = "" }) => {
  return (
    <nav
      className={`
            flex items-center justify-between flex-wrap bg-primary p-6 shadow
            ${className}
            `}
    >
      <div className="flex items-center flex-shrink-0 text-white mr-6">
          <Link className="text-gray-600" to="/">
            <GcIcon className="fill-current h-8 w-8 mr-2 md:transition duration-300 ease-in-out md:transform hover:scale-110 skew-x-3"/>
          </Link>
        <span className=" hidden font-semibold text-xl tracking-tight">
          <Link to="/">
            {siteTitle}
          </Link>
        </span>
      </div>
      <div className="block lg:hidden">
        <button
          onClick={() => {
            toggleClass(document.getElementById("nav-list"), "hidden")
          }}
          className="flex items-center px-3 py-2 border rounded text-gray-200 border-gray-400 hover:text-white hover:border-white md:transition duration-1000 ease-in-out md:transform hover:scale-110"
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        className="w-full block flex-grow lg:flex lg:items-center lg:w-auto hidden"
        id="nav-list"
      >
        <div className="text-md lg:flex-grow flex flex-col lg:flex-row">
          <Link to="/">
            <div className="mt-4 inline-block lg:mt-0 text-gray-200 hover:text-white mr-4">
              Home
            </div>
          </Link>
          <Link to="/about">
            <div className="mt-4 inline-block lg:mt-0 text-gray-200 hover:text-white mr-4">
              About
            </div>
          </Link>
          <Link to="/blog">
            <div className="mt-4 inline-block lg:mt-0 text-gray-200 hover:text-white mr-4">
              Blog
            </div>
          </Link>
        </div>
        <div>
          <Link to="/contact">
            <div className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-gray-500 hover:bg-white mt-4 lg:mt-0">
              Contact
            </div>
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default GcHeader
