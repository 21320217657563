import React from "react"
import { Link } from "gatsby"

function hasClass(elem, className) {
  return new RegExp(" " + className + " ").test(" " + elem.className + " ")
}

function toggleClass(elem, className) {
  var newClass = " " + elem.className.replace(/[\t\r\n]/g, " ") + " "
  if (hasClass(elem, className)) {
    while (newClass.indexOf(" " + className + " ") >= 0) {
      newClass = newClass.replace(" " + className + " ", " ")
    }
    elem.className = newClass.replace(/^\s+|\s+$/g, "")
  } else {
    elem.className += " " + className
  }
}

const GcNav = ({ className = "" }) => {
  return (
    <nav
      className={`
            flex items-center justify-between flex-wrap bg-gray-500 p-6
            ${className}
            `}
    >
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <svg
          className="fill-current h-8 w-8 mr-2"
          width="54"
          height="54"
          viewBox="0 0 54 54"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m21.583593,28l-4.937311,-4.408306l0,2.204132l-1.979616,0l0,-9.700588l10.864679,0l0,1.76749l-2.468653,0l4.937309,4.40836l4.937338,-4.40836l-2.468653,0l0,-1.76749l10.864649,0l0,9.700588l-1.979599,0l0,-2.204132l-4.937332,4.408306l4.937332,4.408318l0,-2.20414l1.979599,0l0,9.700584l-10.864649,0l0,-1.767487l2.468653,0l-4.937338,-4.408375l-4.937309,4.408375l2.468653,0l0,1.767487l-10.864679,0l0,-9.700584l1.979616,0l0,2.20414l4.937311,-4.408318z" />
        </svg>
        <span className="font-semibold text-xl tracking-tight">
          Garrett Cornwell
        </span>
      </div>
      <div className="block lg:hidden">
        <button
          onClick={() => {
            toggleClass(document.getElementById("nav-list"), "hidden")
          }}
          className="flex items-center px-3 py-2 border rounded text-gray-200 border-gray-400 hover:text-white hover:border-white"
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div
        className="w-full block flex-grow lg:flex lg:items-center lg:w-auto hidden"
        id="nav-list"
      >
        <div className="text-sm lg:flex-grow flex flex-col lg:flex-row">
          <Link to="/">
            <div className="mt-4 inline-block lg:mt-0 text-gray-200 hover:text-white mr-4">
              Home
            </div>
          </Link>
          <Link to="/about">
            <div className="mt-4 inline-block lg:mt-0 text-gray-200 hover:text-white mr-4">
              About
            </div>
          </Link>
          <Link to="/blog">
            <div className="mt-4 inline-block lg:mt-0 text-gray-200 hover:text-white mr-4">
              Blog
            </div>
          </Link>
        </div>
        <div>
          <Link to="/contact">
            <div className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-gray-500 hover:bg-white mt-4 lg:mt-0">
              Contact
            </div>
          </Link>
        </div>
      </div>
    </nav>
  )
}

export default GcNav
