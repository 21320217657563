/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import GcNav from "../components/GcNav"
import GcHeader from "../components/GcHeader"

import "./GcLayout.css"

import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const GcLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <GcHeader siteTitle={data.site.siteMetadata.title}>
          <GcNav className=""></GcNav>
        </GcHeader>
        <main className="mb-auto">{children}</main>
        <footer className="p-1 text-xs flex flex-row items-center bg-gray-500 text-gray-300">
          <p className="mb-0">
            &copy;2020 Garrett Cornwell. All rights reserved.
          </p>
        </footer>
      </div>
    </>
  )
}

GcLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GcLayout
